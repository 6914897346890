/*----- ことりまち 特集ページ -----*/
a:hover{text-decoration: none;}
.font_ll{
  font-size: 200%;
}
.font_ll{
  font-size: 175%;
}
img.m1000{
  max-width: 1000px;
}
a.btn-pbl{
  background: #7fcabd;
  color: #fff;
  font-weight: 600;
  font-size: 110%;
  padding: 10px 0px;
}
a.btn-br{
  background:#928280;
  color: #fff;
}
a.btn-br:hover,a.btn-pbl:hover{color: #fff;}
@media screen and (min-width: 768px){

}
@media (min-width: 577px) and (max-width: 840px) {

}
@media (min-width: 841px){

}

@media screen and (max-width: 576px) {

}
