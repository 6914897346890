/*----- Androidケース特集ページ -----*/
a:hover{text-decoration: none;}
.bg_bl{
  background: #EAF6F8;
}
.bg_gr{
  background: #E2F0DB;
}
.font_ll{
  font-size: 200%;
}
.font_l{
  font-size: 175%;
}
.font_s{
  font-size: 50%;
}
.model_listbox:not(:last-child){
  border-bottom: 3px dotted #eee;
}
.model_name{
  text-align: center;
  padding: 45px 0px;
  margin-bottom: 5px;
  border-radius: 5px;
}
.model_name p{
  font-size: 225%;
  font-weight: 600;
  color: #015852;
  letter-spacing: 0.1em;
  margin-bottom: 0;
}
.model_name p span{
  line-height: 1em;
  display: block;
  letter-spacing: 0;
}
ul.model_list li{
  margin-bottom: 5px;
}
ul.model_list li a{
  display: block;
  padding: 3px;
  border: 1px solid #eee;
}
ul.model_list li a:hover{
  background-color: #eee;
}
.model_listbox{
  padding-top: 90px;
  margin-top: -90px;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
#smp_chara-list ul li a{
  background-color: #EA6103;
  /*font-size: 120%;*/
}
.title-border-right {
  display: flex;
  align-items: center;
  }
  .title-border-right:after {
  border-top: 3px solid;
  content: "";
  flex-grow: 1;
  }
  .title-border-right:after {
  margin-left: 1rem;
  }
h4.title-border-right{
  font-size: 2rem;
}
@media screen and (min-width: 768px){

}
@media (min-width: 577px) and (max-width: 840px) {

}
@media (min-width: 841px){

}

@media screen and (max-width: 576px) {
  .model_name{padding: 20px 0px;}
  .font_ll{
    font-size: 130%;
  }
  .font_l{
    font-size: 120%;
  }
}
/*　MobileBattery　*/
.mb_list img{
  margin-top: 10px;
}

/*　Sandcase　*/
#sandcase dl{
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
}
#sandcase dl dt{
  font-size: 120%;
  letter-spacing: 0.1rem;
}
#sandcase  ul.color-list{
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
#sandcase  ul.color-list li{
  width: calc(100% / 6);
  padding: 5px;
}
#sandcase  ul.model-list li a{
  display: block;
  padding: 5px;;
}
#sandcase  ul.model-list li:nth-child(even) a{
  background-color: #f5f5f5;
}
.title-border {
  display: flex;
  align-items: center;
  color: #707070;
  font-weight: 600;
}
.title-border:before,.title-border:after {
  border-top: 2px solid;
  content: "";
  flex-grow: 1;
}
.title-border:before {
  margin-right: 1rem;
}
.title-border:after {
  margin-left: 1rem;
}
/* Glasscase */
.bg_dgray{
  background: #707070;
}
.white{
  color: #fff;
}