/*----- キャラクター特集ページ -----*/

/*　カテゴリーリスト・ボタン共通　*/
a:hover{text-decoration: none;}
.bg_iv{
  background: #FFFAF4;
}
.top-img h2{
  margin: 10px 0px 5px;
  padding: 0px;
}
.chara-lp h3,.chara-lp h4{
  width: 100%;
  margin: 30px auto 0px !important;
  text-align: center;
}
.chara-lp h3 img{
  margin-top: 80px;
  width: 100%;
  max-width: 230px;
}
.chara-lp .point h3 img{
  max-width: 186px;
}

.puitem-list li{
  font-size: 1.2rem;
  margin-bottom: 5px;
}
.pucate-item{
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.pucate-item img{
  border: 1px solid #ddd;
  margin-bottom: 5px;
}
p.s-cate{
  text-align: center;
  font-size: 1.8rem;
}
.new{
  background-color: #80CAE1;
  border-radius: 4px;
  color: #ffffff;
  font-size: 80%;
  font-weight: bold;
  padding: 2px;
  margin-right: 4px;
}

@media (min-width: 577px) and (max-width: 840px) {

}
@media (min-width: 841px){

  .chara-lp .lp_catelist img{
    max-width: 230px;
  }
  .top-img h2{
    margin: 80px 0px 5px;
    padding: 0px;
  }
}

@media screen and (max-width: 576px) {

  .top-img h2{
    margin: 10px 0px;
  }
}
