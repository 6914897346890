/*----- キャラクター特集ページ -----*/

/*　カテゴリーリスト・ボタン共通　*/
a:hover{text-decoration: none;}
.bg_bl{
  background: #EAF6F8;
}
.bg_gr{
  background: #E2F0DB;
}

.chara-lp div.bg_or{
  background: rgba(255,216,129,0.9);
}
.chara-lp div.bg_ye{
  background: rgba(255,252,207,0.9);
}

.chara-lp .lp_catelist{
  text-align: center;
  z-index: 999;
  box-shadow: 0px 2px 5px #eee;
}
.chara-lp .lp_catelist img{
  width: 90%;
  max-width: 377px;
  margin-bottom: 5px;
}
.chara-lp .lp_catelist li{
  display: inline-block;
  margin: 0px 2px 5px;
  /*width: calc(95% / 7);*/
}
.chara-lp .lp_catelist li a{
  display: block;
  background: darkorange;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
}
.chara-lp .bg_ye li a{
  background: #84C067;
}

.chara-lp .lp_catelist li a:hover{
  text-decoration: none;
  opacity: 0.8;
}

a.btn_or,a.btn_gr{
  border: 2px solid;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
}
a.btn_or{
  border-color: darkorange;
  color: darkorange;
}
a.btn_or:hover{
  background-color: darkorange;
  color: #fff;
}
a.btn_gr{
  border-color: #84C067;
  color: #84C067;
}
a.btn_gr:hover{
  background-color: #84C067;
  color: #fff;
}
/*　にゃんすけLP　*/
.top-img h2{
  margin: 10px 0px 5px;
  padding: 0px;
}
.chara-lp h3,.chara-lp h4{
  width: 100%;
  margin: 30px auto 0px !important;
  text-align: center;
}
.chara-lp h3 img,.chara-lp h4 img{
  width: 100%;
  max-width: 750px;
}
dl.illustrator{
  border: 7px double #92D1DF;
  border-radius: 10px;
  width: 95%;
  margin: 0 auto;
}
dl.illustrator dt,.illustrator dd{
  display: block;
  padding: 10px;
  text-align: center;
  width: 100%;
}
dl.illustrator dt img{
  max-height: 90px;
  width: auto;
}
dl.illustrator dd{position: relative;}
dl.illustrator dd .sns-link{
  display: block;
  font-size: 3rem;
  letter-spacing: 0.1em;
}

.puitem-list li{
  font-size: 1.2rem;
  margin-bottom: 5px;
}
.pucate-item{
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.pucate-item img{
  border: 1px solid #ddd;
  margin-bottom: 5px;
}
p.s-cate{
  text-align: center;
  font-size: 1.8rem;
}
h4 img.ttl_furupuru{
  width: 80%;
  max-width: 500px;
}
.bg_bl .container,.bg_or .container{
  padding: 0px;
}
.nui-slist{
  width: 100%;
}
.nui-slist a img{
  width: calc(96% / 2);
  display: inline-block;
  margin: 0px 1%
}
.nui-slist p{
  text-align: center;
  margin: 0px 0px 5px;
}

/*　しばんばんLP　*/
#banban-lp dl.illustrator{
  border-color: #84C067;
}
#banban-lp dl.illustrator dd .sns-link a,#banban-lp .pu-item a,#banban-lp .pucate-item a,#piyokomame-lp dl.illustrator dd .sns-link a,#piyokomame-lp .pu-item a,#piyokomame-lp .pucate-item a{
  color: #555;
}
#banban-lp .pu-item a{
}
.pucate-item-5 li{
  display: inline-block;
  width: calc(96% / 2);
  margin: 0px 1%;
  vertical-align: top;
}
@media screen and (min-width: 768px){
  main.others {
    margin-top: 84px !important;
  }

  dl.illustrator{width: 100%;}
  dl.illustrator dt,.illustrator dd{
    display: inline-block;
    vertical-align: middle;
  }
  dl.illustrator dt{width: 40%;}
  dl.illustrator dd{width: 60%;
    text-align: left;}
    dl.illustrator dd .sns-link{
      position: absolute;
      right: 20px;
    bottom: -10px;
  }
  .pucate-item-5 li{
    width: calc(90% / 5);}
}
@media (min-width: 577px) and (max-width: 840px) {
  .chara-lp .lp_catelist li{
    display: inline-block;
    margin: 0px 2px 5px;
    width: calc(95% / 5);
  }
}
@media (min-width: 841px){
  .chara-lp .lp_catelist{ position: fixed;}
  .chara-lp .lp_catelist li{
    display: inline-block;
    margin: 0px 2px 5px;
    width: calc(95% / 7);
  }
  .chara-lp .lp_catelist dl{
    max-width: 1140px;
    margin: 0 auto;
  }
  .chara-lp .lp_catelist dl dt,.chara-lp .lp_catelist dl dd{
    display: inline-block;
    vertical-align: top;
  }
  .chara-lp .lp_catelist dl dd{
    width: calc(100% - 230px);
  }
  .chara-lp .lp_catelist img{
    max-width: 230px;
  }
  .top-img h2{
    margin: 130px 0px 5px;
    padding: 0px;
  }
}

@media screen and (max-width: 576px) {
  .chara-lp .lp_catelist{
    position: inherit;
  }
  .chara-lp .lp_catelist li{
    width:inherit;
  }
  .top-img h2{
    margin: 10px 0px;
  }
}
