main.anniv30th{
  /* background-color: #F9D3E3; */

  margin-top: 0 !important;
  padding-top: 130px;
    padding-bottom: 35%;
    position: relative;

}
main.anniv30th > .container{position: relative; z-index: 10;}

main.anniv30th::before{
  position: absolute;
  content: '';
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #F9D3E3;
  z-index: -1;
}
main.anniv30th::after{
  position: absolute;
  content: '';
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/img/30th/30th_chara.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
}

.anniv30th_wrap footer{
  position: relative !important;
  z-index: 10;
  background-color: #FFF;
}
.anniv30th_wrap canvas.confetti{
  width: 100%;
  height: 100%;
  position: fixed;
}
.anniv30th .ranklist{
  margin: 0 auto 0;
  width: 90%;
  max-width: 1000px;
}
.anniv30th .ranklist ul{
  list-style: none;
  width:100%;
  margin: 0;
  padding: 0;

  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  	justify-content: space-between;
}



.anniv30th .ranklist ul li{
  width:100%;
  margin: 0 0 5%;
  padding: 0;
  min-width: 8%;
  flex-basis: 47%;
  position: relative;
}
.anniv30th .ranklist ul li:first-child{flex-basis: 56%; margin: 0 22% 7%;}

.anniv30th .ranklist ul li::after{
  position: absolute;
  content: '';
  width: 18%;
  height: 0;
  padding-top: 18%;
  background-image: url(/img/30th/30th_rank01.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top:-5%;
  left: -8%;
}
.anniv30th .ranklist ul li:nth-child(1)::after{
  width: 20%;  padding-top: 20%;
}
.anniv30th .ranklist ul li:nth-child(2)::after{background-image: url(/img/30th/30th_rank02.png);
  width: 20%;  padding-top: 20%;
}
.anniv30th .ranklist ul li:nth-child(3)::after{background-image: url(/img/30th/30th_rank03.png);
  width: 20%;  padding-top: 20%;
}
.anniv30th .ranklist ul li:nth-child(4)::after{background-image: url(/img/30th/30th_rank04.png);}
.anniv30th .ranklist ul li:nth-child(5)::after{background-image: url(/img/30th/30th_rank05.png);}
.anniv30th .ranklist ul li:nth-child(6)::after{background-image: url(/img/30th/30th_rank06.png);}
.anniv30th .ranklist ul li:nth-child(7)::after{background-image: url(/img/30th/30th_rank07.png);}
.anniv30th .ranklist ul li:nth-child(8)::after{background-image: url(/img/30th/30th_rank08.png);}
.anniv30th .ranklist ul li:nth-child(9)::after{background-image: url(/img/30th/30th_rank09.png);}
.anniv30th .ranklist ul li:nth-child(10)::after{background-image: url(/img/30th/30th_rank10.png);}
.anniv30th .ranklist ul li:nth-child(11)::after{background-image: url(/img/30th/30th_rank11.png);
    width: 15%;  padding-top: 15%;
}

.anniv30th .ranklist ul li a{
  display: block;
  transition: transform .3s ease-out;
}
.anniv30th .ranklist ul li .noevent{
  display: block;
  pointer-events: none;
  cursor: default;
}
.anniv30th .ranklist ul li img{
  width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) {
  .anniv30th .ranklist ul li a:hover{
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);

  }
}
@media screen and (max-width: 767px) {
  main.anniv30th{padding-top: 110px;}
  .anniv30th .ranklist ul li,
  .anniv30th .ranklist ul li:first-child{  flex-basis: 100%;  margin: 0 0 5%;}
}
@media screen and (max-width: 576px) {

}
